import React from "react"
import { FaLinkedin, FaTwitterSquare } from "react-icons/fa"
import { GrMail } from "react-icons/gr"

const data = [
  {
    id: 1,
    icon: <GrMail className="social-icon"></GrMail>,
    url: "mailto:danielad@uvic.ca",
  },
  {
    id: 2,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com",
  },
  {
    id: 3,
    icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
    url: "https://www.twitter.com/DanaHDamian",
  },
]
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link">
        {link.icon}
      </a>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}
